import * as React from 'react';
import { useActions, useSelector } from '../../state';
import { msgsToComponents } from '../../utils/msgs-to-components';
import { PersonInput } from '../PersonInput';

const msgs = {
  AddressLabel: 'Adresse',
  EmailLabel: 'E-post',
  NameLabel: 'Fullt navn',
  PhoneLabel: 'Mobilnummer',
  PnumLabel: 'Fødselsnummer eller d-num\xADmer',
  ZipLabel: 'Postnummer',
};

const Msg = msgsToComponents(msgs);

export const FormOwnerForm: React.FC = () => {
  const person = useSelector(e => e.editor.people[e.editor.formOwner]);
  const { editorActions } = useActions();
  const onChange = editorActions.setPersonInfo;

  return (
    <form
      noValidate={true}
      onSubmit={evt => {
        evt.preventDefault();
      }}
    >
      <PersonInput
        person={person}
        inputs={['name', 'pNum', 'cadasterAddress', 'email', 'phone']}
        onChange={onChange}
        labelOverrides={{
          cadasterAddress: <Msg.AddressLabel />,
          name: <Msg.NameLabel />,
          email: <Msg.EmailLabel />,
          phone: <Msg.PhoneLabel />,
          pNum: <Msg.PnumLabel />,
        }}
        editType="edit"
      />
    </form>
  );
};
