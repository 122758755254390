import * as React from 'react';
import { useMediaLayout } from 'use-media';
import { LargeHeaderBar } from './LargeHeaderBar';
import { SmallHeaderBar } from './SmallHeaderBar';

export interface MenuItem {
  href: string;
  text: string;
}

export interface Props {
  menuItems: MenuItem[];
  navigationVisible: boolean;
  className?: string;
}

export const HeaderBar: React.FC<Props> = props => {
  const matches = useMediaLayout('(max-width: 880px)');

  if (matches) {
    return <SmallHeaderBar {...props} />;
  }

  return <LargeHeaderBar {...props} />;
};
