import { formatters } from 'folio-common-utils';
import * as React from 'react';
import { type Props, TextInput } from '../TextInput';
import {
  type HtmlTextInputElement,
  type State,
  getNextCursorPosition,
  setCursorPosition,
} from '../formatting-inputs';

export type { Props } from '../TextInput';

function removeLeadingAndTrailingJunk(str: string) {
  // The value can only start with a digit and end with digits, or spaces as it
  // may be a partial telephone number if the user isn't done typing
  return str.replace(/^[^\d+]*|[^\d ]*$/g, '').replace(/\s+$/, ' ');
}

function format(str: string) {
  return formatters.formatTelephoneNumber(str).replace(/\u{A0}/gu, ' ');
}

/**
 * Check if the input, after removing spaces, consists of exactly
 * 8 digits.
 */
export function isTelephoneNumber(str: string) {
  return /^\d{8}$/.test(str.replace(/\s/g, ''));
}

export class TelephoneNumberInput extends React.PureComponent<Props, State> {
  private inputRef: React.RefObject<HtmlTextInputElement> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      cursorPos: 0,
    };
  }

  setCursorPosition(pos: number) {
    const input = this.inputRef.current;
    if (!input) {
      return;
    }

    setCursorPosition(input, pos);
  }

  handleChange = (value: string) => {
    const input = this.inputRef.current;
    if (!input) {
      return;
    }

    value = value.replace(/^\+47|0047\s?/, '');

    // Workaround for Safari bug, which autofills country code without the plus sign
    if (value.startsWith('47') && value.replace(/\s/g, '').length === 10) {
      value = value.slice(2);
    }

    // Remove leading and trailing characters that should not be part of the
    // value, but may be the result of accidental copying.
    const cleanedValue = removeLeadingAndTrailingJunk(value);

    // If there are characters other than the allowed ones (digits, plus and spaces)
    // at this point, discard anything that was typed or pasted as it's invalid.
    if (/[^\d+ ]/.test(cleanedValue)) {
      const offset = value.length - this.props.value.length;
      this.setState({
        cursorPos: input.selectionEnd - offset,
      });
      this.forceUpdate();
      return;
    }

    let formattedValue;
    let offset = 0;
    const cursorPos = input.selectionStart;
    const isCursorAtEnd = cursorPos === value.length;
    if (isCursorAtEnd) {
      // To avoid weird situations in some scenarios, only format when the cursor
      // is at the end.
      formattedValue = format(cleanedValue);
      offset = formattedValue.length - value.length;
    } else {
      // If the cursor is not at the end, remove any spaces at the end. This
      // avoids the issue that dangling whitespace (which is hard to spot)
      // prevents formatting since formatting only occurs when the cursor is at
      // the end.
      formattedValue = cleanedValue.replace(/\s*$/, '');
    }

    this.props.onChange(formattedValue);

    this.setState({
      cursorPos: cursorPos + offset,
    });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const cursorPos = getNextCursorPosition(event);
    if (cursorPos != null) {
      this.setCursorPosition(cursorPos);
    }
  };

  componentDidUpdate() {
    this.setCursorPosition(this.state.cursorPos);
  }

  render() {
    const value = format(this.props.value);
    const maxLength = isTelephoneNumber(value) ? 11 : undefined;
    return (
      <TextInput
        autoComplete="mobile tel-national"
        {...this.props}
        value={value}
        type="tel"
        maxLength={maxLength}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        inputRef={this.inputRef}
      />
    );
  }
}
