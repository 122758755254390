import styled from '@emotion/styled';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { font300book } from '../fonts';
import { type Size, dynamicStyles, textLikeInput } from '../text-field';

const Container = styled.span<{ inputDisabled: boolean }>`
  color: ${({ inputDisabled }) => (inputDisabled ? colors.wcagGray : null)};
  display: inline-grid;
  grid-template-areas: 'overlap';
  width: 100%;
`;

const Input = styled.input<{
  widgetSize: Size;
  hasIcon: boolean;
  rightContentWidth: number;
}>`
  ${textLikeInput};
  ${({ widgetSize, hasIcon, rightContentWidth }) =>
    dynamicStyles(widgetSize, hasIcon, rightContentWidth)};
  letter-spacing: 0.5px;
  grid-area: overlap;
  width: 100%;
`;

const Icon = styled.span<{ size: Size }>`
  grid-area: overlap;
  align-self: center;
  justify-self: start;
  z-index: 0;
  pointer-events: none;

  display: flex;
  margin-left: ${({ size }) => (size === 'medium' ? '16px' : '24px')};
`;

const RightContent = styled.div<{ size: Size; interactable: boolean }>`
  grid-area: overlap;
  align-self: center;
  justify-self: end;
  z-index: 0;
  pointer-events: ${({ interactable }) => (interactable ? null : 'none')};
  margin-right: ${({ size }) => (size === 'medium' ? '16px' : '24px')};
  ${({ size }) => (size === 'large' ? font300book : null)};
`;

export interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  icon?: React.ReactNode;
  size?: Size;
  rightContent?: {
    content: React.ReactNode;
    width: number;
    interactable?: boolean;
  };
}

const BaseTextInputContainer: React.ForwardRefRenderFunction<
  HTMLInputElement,
  Props
> = (props, ref) => {
  const { icon, size = 'medium', disabled, rightContent, ...rest } = props;
  return (
    <Container inputDisabled={disabled || false}>
      {icon && <Icon size={size}>{icon}</Icon>}
      <Input
        ref={ref}
        widgetSize={size}
        hasIcon={icon != null}
        disabled={disabled}
        rightContentWidth={rightContent?.width ?? 0}
        {...rest}
      />
      {rightContent && (
        <RightContent
          size={size}
          interactable={rightContent.interactable ?? false}
        >
          {rightContent.content}
        </RightContent>
      )}
    </Container>
  );
};

export const TextInputContainer = React.forwardRef(BaseTextInputContainer);
