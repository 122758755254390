import styled from '@emotion/styled';
import * as React from 'react';
import { font200medium } from '../fonts';

export interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
  for?: string;
}

const BaseLabel = styled.label`
  display: block;
  padding-bottom: 4px;
  letter-spacing: 0.25px;
  ${font200medium};
`;

export const Label: React.FC<Props> = ({ for: ourFour, htmlFor, ...props }) => {
  return <BaseLabel htmlFor={ourFour || htmlFor} {...props} />;
};
