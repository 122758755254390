import type * as React from 'react';
export const FikenLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={25}
    fill="none"
    viewBox="0 0 21 25"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#75ABF7"
      fillRule="evenodd"
      d="M9.7065 11.9965A2.0129 2.0129 0 0 1 7.6959 9.979v-.7802c-.0051-4.3813 3.538-7.9396 7.9193-7.9532a7.86 7.86 0 0 1 4.4293 1.359c.9029.6288 1.1346 1.8656.5206 2.7785-.6142.913-1.8469 1.1647-2.7698.5654a3.8593 3.8593 0 0 0-2.1801-.6678c-2.1574.0077-3.9014 1.7606-3.8982 3.9181v.7837A2.0142 2.0142 0 0 1 9.7065 12"
      clipRule="evenodd"
    />
    <path
      fill="#5239BA"
      fillRule="evenodd"
      d="M16.393 13.8901h-4.6784V9.1989a2.0104 2.0104 0 0 0-3.0343-1.7982 2.0105 2.0105 0 0 0-.9844 1.7982v4.6947H3.0175C1.9033 13.8936 1 14.7969 1 15.9111c0 1.1143.9033 2.0176 2.0175 2.0176H7.696v4.6947a2.0103 2.0103 0 0 0 .9907 1.7815 2.0104 2.0104 0 0 0 3.0292-1.7815v-4.6982h4.6784c1.1142 0 2.0175-.9033 2.0175-2.0176 0-1.1142-.9033-2.0175-2.0175-2.0175"
      clipRule="evenodd"
    />
  </svg>
);
