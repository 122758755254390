import * as services from '../../services';
import type { Dispatch, GetState } from '../types';
import { actions } from './actions';

function normalizeTerm(term: string) {
  return term.replace(/[. ]/g, '');
}

export function searchForCompany(term: string) {
  return async (dispatch: Dispatch) => {
    dispatch(actions.startSearch(term));
    const termLooksLikeOrgId = normalizeTerm(term).match(/\d{9}/);
    // If, after removing spaces and dots, the term consists of 9 digits,
    // the search is probably for an organization id.
    const result = termLooksLikeOrgId
      ? await services.searchByOrgNum({ id: normalizeTerm(term) })
      : await services.searchByName({ name: term });

    const items = result.items.sort(sortAsToTop);

    // term might have been normalized, so set the original term here
    dispatch(actions.finishSearch({ state: 'finished', term, items }));
  };
}

export function validateCompanyName(name: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const item = state.companySearch.valid[name];
    if (item) {
      return;
    }

    dispatch(actions.startValidation(name));
    const res = await services.isAvailableCompanyName({ name });
    dispatch(
      actions.finishValidation({ name, state: res ? 'valid' : 'invalid' }),
    );
  };
}

export function sortAsToTop(a, b) {
  const aInc = a.incorporation;
  const bInc = b.incorporation;

  if (aInc === 'AS' && bInc !== 'AS') {
    return -1;
  }

  if (aInc !== 'AS' && bInc === 'AS') {
    return 1;
  }

  if (aInc === 'ENK' && bInc !== 'ENK') {
    return 1;
  }

  if (aInc !== 'ENK' && bInc === 'ENK') {
    return -1;
  }

  return 0;
}
