import { type ActionType, createAction } from 'typesafe-actions';
import type { CompanySearchResult } from './';

export const actions = {
  startSearch: createAction('START_SEARCH')<string>(),
  finishSearch: createAction('FINISH_SEARCH')<CompanySearchResult>(),
  startValidation: createAction('START_VALIDATION')<string>(),
  finishValidation: createAction('FINISH_VALIDATION')<{
    name: string;
    state: 'valid' | 'invalid';
  }>(),
};

export type CompanySearchAction = ActionType<typeof actions>;
