import * as React from 'react';
import { useLocation } from 'react-router-dom';

export function useScrollToTopOnPathChange() {
  const { pathname } = useLocation();
  const prevPath = React.useRef(pathname);

  React.useLayoutEffect(() => {
    if (pathname !== prevPath.current) {
      window.scrollTo(0, 0);
      prevPath.current = pathname;
    }
  }, [pathname]);
}
