import { fnum } from 'folio-common-utils';
import * as React from 'react';
import { type Props, TextInput } from '../TextInput';
import {
  type HtmlTextInputElement,
  type State,
  getNextCursorPosition,
  setCursorPosition,
} from '../formatting-inputs';

export type { Props } from '../TextInput';

function removeLeadingAndTrailingJunk(str: string) {
  return str.replace(/^[^\d]*|[^\d ]*$/g, '').replace(/\s+$/, ' ');
}

function zeroPad(str: string) {
  return str.padStart(2, '0');
}

const isoDateRe = /(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})/;
// Matches 1/1/2000 or 1.1.2000
const otherDateRe = /(?<day>\d\d?)[./](?<month>\d\d?)[./](?<year>\d{4})/;

type DateReMatch = {
  groups: { year: string; month: string; day: string };
} | null;

export class BirthNumberInput extends React.PureComponent<Props, State> {
  private inputRef: React.RefObject<HtmlTextInputElement> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      cursorPos: 0,
    };
  }

  setCursorPosition(pos: number) {
    const input = this.inputRef.current;
    if (!input) {
      return;
    }

    setCursorPosition(input, pos);
  }

  handleChange = (value: string) => {
    const input = this.inputRef.current;
    if (!input) {
      return;
    }

    // Convert autofilled value in yyyy-MM-dd, d.M.yyyy or d/M/yyyy format to ddMMyy
    const dateMatch = (value.match(isoDateRe) ||
      value.match(otherDateRe)) as DateReMatch;
    if (dateMatch?.groups) {
      const { year, month, day } = dateMatch.groups;
      value = `${zeroPad(day)}${zeroPad(month)}${year.slice(2)}`;
    }

    // Remove leading and trailing characters that should not be part of the
    // value, but may be the result of accidental copying.
    const cleanedValue = removeLeadingAndTrailingJunk(value);

    // If there are characters other than the allowed ones (digits and spaces)
    // at this point, discard anything that was typed or pasted as it's invalid.
    if (/[^\d ]/.test(cleanedValue)) {
      const offset = value.length - this.props.value.length;
      this.setState({
        cursorPos: input.selectionEnd - offset,
      });
      this.forceUpdate();
      return;
    }

    let formattedValue;
    let offset = 0;
    const cursorPos = input.selectionStart;
    const isCursorAtEnd = cursorPos === value.length;
    if (isCursorAtEnd) {
      // To avoid weird situations in some scenarios, only format when the cursor
      // is at the end.
      formattedValue = fnum.format(cleanedValue);
      offset = formattedValue.length - value.length;
    } else {
      // If the cursor is not at the end, remove any spaces at the end. This
      // avoids the issue that dangling whitespace (which is hard to spot)
      // prevents formatting since formatting only occurs when the cursor is at
      // the end.
      formattedValue = cleanedValue.replace(/\s*$/, '');
    }

    this.props.onChange(formattedValue);

    this.setState({
      cursorPos: cursorPos + offset,
    });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const cursorPos = getNextCursorPosition(event);
    if (cursorPos != null) {
      this.setCursorPosition(cursorPos);
    }
  };

  componentDidUpdate() {
    this.setCursorPosition(this.state.cursorPos);
  }

  render() {
    const value = fnum.format(this.props.value);
    return (
      <TextInput
        autoComplete="turn-it-off"
        {...this.props}
        value={value}
        inputMode="numeric"
        maxLength={12}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        inputRef={this.inputRef}
      />
    );
  }
}
