import * as React from 'react';
import { Link } from 'react-router-dom';
import { DummyButton, linkStyle } from '../buttons';

interface Props {
  to: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const ContinueLink: React.FC<React.PropsWithChildren<Props>> = props => (
  <Link
    to={{
      pathname: props.to,
      search: window.location.search,
    }}
    onClick={props.onClick}
    css={linkStyle}
  >
    <DummyButton size="large" fullWidth={true}>
      {props.children}
    </DummyButton>
  </Link>
);
