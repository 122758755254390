import * as services from '../../services';
import type { Dispatch, GetState } from '../types';
import { actions } from './actions';

export function validateCompanyName(name: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const item = state.companyCheck[name];

    if (item) {
      return;
    }

    dispatch(actions.startValidation(name));
    const res = await services.checkCompanyName(name);
    dispatch(actions.finishValidation({ name, state: res }));
  };
}
