import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as React from 'react';
import { useMediaLayout } from 'use-media';
import { Grid } from '../Grid';
import { Page } from '../Page';

interface Props {
  className?: string;
}

const StyledFooter = styled.footer`
  margin-top: 6rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--purple-900);
  color: #fff;

  @media (max-width: 880px) {
    margin-top: 4rem;
  }

  @media not all and (min-width: 500px) {
    /* Some extra space on the bottom, so that the text is not too close to the edge on e.g. iPhone X */
    padding-bottom: 4rem;
  }
`;

const SmallFooter = styled.div`
  padding: 2rem 0;
`;

const SmallGrid = styled.div`
  padding: 0 2rem;
`;

const SmallFooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;

  @media (max-width: 560px) {
    grid-template-columns: none;
  }
`;

const whiteLink = css`
  color: #fff;
`;

const FooterContent: React.FC = () => {
  return (
    <SmallFooterGrid>
      <div>
        <p
          css={css`
            margin: 0;
          `}
        >
          En tjeneste fra{' '}
          <a href="https://fiken.no" css={whiteLink}>
            Fiken
          </a>{' '}
          og{' '}
          <a href="https://folio.no" css={whiteLink}>
            Folio
          </a>
          .
        </p>
      </div>
      <p
        css={css`
          margin: 0;
        `}
      >
        <a
          href="https://fiken.no/starte-as/sluttbrukeravtale"
          css={whiteLink}
          target="_blank"
        >
          Sluttbrukeravtale
        </a>
      </p>
    </SmallFooterGrid>
  );
};

export const Footer: React.FC<Props> = props => {
  const { className } = props;
  const content = <FooterContent />;
  const smallish = useMediaLayout('(max-width: 880px)');

  return (
    <StyledFooter className={className}>
      <Page>
        <SmallFooter>
          {smallish ? <Grid>{content}</Grid> : <SmallGrid>{content}</SmallGrid>}
        </SmallFooter>
      </Page>
    </StyledFooter>
  );
};
