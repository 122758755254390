import * as React from 'react';
import { ErrorRoute } from './ErrorRoute';

export class RouteErrorBoundary extends React.PureComponent<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorRoute />;
    }

    return this.props.children;
  }
}
