import { css, keyframes } from '@emotion/react';
import { colors } from 'folio-design-tokens';
import * as React from 'react';

export interface Props {
  size?: number;
  strokeWidth?: number;
  color?: string;
}

const SpinnerAnimation = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

const spinner = css`
  display: block;
  animation: ${SpinnerAnimation} 1s linear infinite;
`;

export const Spinner: React.FC<Props> = ({
  size = 24,
  strokeWidth = 2,
  color = colors.blue,
  ...rest
}) => {
  const radius = size / 2 - strokeWidth / 2 - 1; // -1 for padding
  const length = Math.PI * (radius * 2);

  return (
    <svg
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      height={size}
      css={spinner}
      {...rest}
    >
      <circle
        r={radius}
        cx={size / 2}
        cy={size / 2}
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={length * 0.75}
        strokeLinecap="round"
      />
    </svg>
  );
};

export const ProgressSpinner: React.FC<Props> = ({
  size = 24,
  color = colors.blue,
}) => (
  // TODO: Is the div needed?
  <div>
    <Spinner size={size} color={color} strokeWidth={size / 12} />
  </div>
);
