import { css } from '@emotion/react';
import {
  type Button as BaseButton,
  type TextButton as BaseTextButton,
  baseButtonStyle,
  fonts,
} from 'folio-common-components';
import * as React from 'react';

const primaryButtonStyles = css`
  border: 1px solid transparent;
  background: var(--green-700);
  color: #fff;
  padding: 11px 32px;
  border-radius: 4px;
  display: inline-block;
  ${fonts.font300medium};
  transition: 0.2s background, 0.2s box-shadow, border-color 0.2s;

  :disabled {
    background: var(--green-500);
    cursor: default;
  }

  :not(:disabled):hover {
    border-color: var(--fiken-green-hover);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    text-decoration: underline;
  }

  :not(:disabled):focus {
    border-color: var(--fiken-green-hover);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  }

  :not(:disabled):active {
    background: var(--fiken-green-hover);
  }
`;

const secondaryButtonStyles = css`
  border: 1px solid var(--purple-800);
  background: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  display: inline-block;
  transition: 0.2s background, 0.2s box-shadow, border-color 0.2s;

  :disabled {
    border-color: #897be4;
    color: #939393;
    cursor: default;
  }

  :not(:disabled):hover {
    background: var(--fiken-purple-extra-light);
    text-decoration: underline;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  }

  :not(:disabled):focus {
    background: var(--fiken-purple-extra-light);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  }

  :not(:disabled):active {
    background: var(--fiken-purple-light);
  }
`;

export const Button: React.FC<React.ComponentProps<typeof BaseButton>> = ({
  level = 'primary',
  fullWidth,
  ...rest
}) => {
  return (
    <button
      type="button"
      css={[
        baseButtonStyle,
        level === 'primary' ? primaryButtonStyles : secondaryButtonStyles,
        fullWidth
          ? css`
              width: 100%;
            `
          : null,
      ]}
      {...rest}
    />
  );
};

export const DummyButton: React.FC<React.ComponentProps<typeof BaseButton>> = ({
  level = 'primary',
  fullWidth,
  ...rest
}) => {
  return (
    <span
      css={[
        baseButtonStyle,
        level === 'primary' ? primaryButtonStyles : secondaryButtonStyles,
        fullWidth
          ? css`
              width: 100%;
            `
          : null,
      ]}
      {...rest}
    />
  );
};

export const TextButton: React.FC<
  React.ComponentProps<typeof BaseTextButton>
> = props => {
  return (
    <button
      type="button"
      css={[
        baseButtonStyle,
        css`
          color: var(--system-blue-700);
        `,
      ]}
      {...props}
    />
  );
};

export const linkStyle = css`
  text-decoration: none;
  display: inline-block;
  width: 100%;

  :hover > * {
    text-decoration: underline;
    border-color: var(--fiken-green-hover);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  }

  :focus {
    box-shadow: none;
  }

  :focus > * {
    border-color: var(--fiken-green-hover);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  }

  :active > * {
    background: var(--fiken-green-hover);
  }

  & > * {
    transition: 0.2s background, 0.2s box-shadow, border-color 0.2s;
  }
`;

export const ButtonLink: React.FC<JSX.IntrinsicElements['a']> = ({
  children,
  ...rest
}) => (
  <a css={linkStyle} {...rest}>
    <DummyButton size="large" fullWidth={true}>
      {children}
    </DummyButton>
  </a>
);
