import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PlainButton } from 'folio-common-components';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { useActions, useSelector } from '../../state';
import type { Message } from '../../state/app';
import { zIndex } from '../z-index';

export interface Props {
  messages: Message[];
  onDismissMessage: (id: string) => any;
}

const Wrapper = styled.div`
  position: fixed;
  z-index: ${zIndex.messagesBar};
  max-width: 360px;
  width: 100%;
  top: 0;
  right: 0;
  padding: 16px;
`;

const MessagesBarItem = styled.div`
  background-color: white;
  color: ${colors.black};
  border: 3px solid ${colors.black};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 10px 10px 0 0 rgba(29, 29, 29, 0.2);
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
`;

const MessagesBarItemBody = styled.div`
  flex: 1 1 auto;
  margin-right: 16px;
  word-break: break-word;
`;

export const MessagesBar: React.FC = () => {
  const messages = useSelector(e => e.app.messages);
  const { appActions } = useActions();
  const msgs = [...messages].sort((a, b) => {
    return b.timestamp - a.timestamp;
  });

  if (msgs.length) {
    return (
      <Wrapper>
        {msgs.map(msg => (
          <MessagesBarItem key={msg.id}>
            <MessagesBarItemBody>{msg.body}</MessagesBarItemBody>
            <div>
              <PlainButton
                onClick={() => {
                  appActions.deleteMessage(msg.id);
                }}
                aria-label="Lukk"
              >
                <svg
                  width={12}
                  height={12}
                  viewBox="0 0 12 12"
                  css={css`
                    display: block;
                  `}
                  stroke={colors.black}
                  strokeWidth={3}
                >
                  <line x1="1" y1="1" x2="11" y2="11" />
                  <line x1="11" y1="1" x2="1" y2="11" />
                </svg>
              </PlainButton>
            </div>
          </MessagesBarItem>
        ))}
      </Wrapper>
    );
  } else {
    return null;
  }
};
