import { mountPath } from '../../paths';

export interface CadasterHit {
  addressText: string;
  postalCode: string;
  postalPlace: string;
}

export interface CadasterResult {
  term: string;
  hits: CadasterHit[];
  error?: boolean;
}

export async function fetchCadasterData(term: string): Promise<CadasterResult> {
  const url = new URL(`${mountPath}/matrikkel`, window.location.href);
  url.searchParams.set('term', term);
  const res = await fetch(url.href);
  if (res.ok) {
    const body: CadasterResult = await res.json();
    return body;
  } else {
    return { hits: [], term, error: true };
  }
}
