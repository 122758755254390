import { msgsToComponents } from '../utils/msgs-to-components';

const msgs = {
  Address: 'Adresse',
  Board: 'Styre',
  Cancel: 'Avbryt',
  Continue: 'Fortsett',
  Date: 'Stiftelsesdato',
  Done: 'Lagre',
  Email: 'E-post',
  Name: 'Fullt navn',
  Onward: 'Fortsett',
  Owner: {
    one: 'Eier',
    many: 'Eiere',
  },
  Phone: 'Mobilnummer',
  PostalCode: 'Postnummer',
  Purpose: 'Formål',
  PNum: {
    long: 'Fødselsnummer eller d-num\xADmer',
    short: 'Fødselsnummer',
  },
  Role: 'Rolle',
  Save: 'Lagre',
};

export const Labels = msgsToComponents(msgs);
