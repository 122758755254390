import type { KeyboardEvent } from 'react';

export type State = {
  cursorPos: number;
};

export type HtmlTextInputElement = HTMLInputElement & {
  selectionStart: number;
  selectionEnd: number;
};

export function getNextCursorPosition(event: KeyboardEvent): number | null {
  const target = event.target as HtmlTextInputElement;
  const { value, selectionStart, selectionEnd } = target;
  let cursorPosition = null;

  // If the cursor is just after a space, and Backspace is pressed, or if the
  // cursor is just before a space and Delete is pressed, the cursor must be
  // manually positioned before or after the space to delete the right
  // character. Also, if exactly one space is selected, and Backspace or
  // Delete is pressed, the cursor is manually positioned before or after the
  // space.
  if (event.key === 'Backspace' && selectionEnd !== value.length) {
    if (value[selectionStart - 1] === ' ' && selectionStart === selectionEnd) {
      // If the cursor is just after a space when pressing Backspace
      // "123 |456" -> "123| 456"
      cursorPosition = selectionStart - 1;
    } else if (value.slice(selectionStart, selectionEnd) === ' ') {
      // If exactly one space is selected, reposition the cursor
      cursorPosition = selectionStart;
    }
  } else if (event.key === 'Delete') {
    if (value[selectionStart] === ' ' && selectionStart === selectionEnd) {
      // If the cursor is just before a space when pressing Delete
      // "123| 456" -> "123 |456"
      cursorPosition = selectionStart + 1;
    } else if (value.slice(selectionStart, selectionEnd) === ' ') {
      // If exactly one space is selected, reposition the cursor
      cursorPosition = selectionStart + 1;
    }
  }

  if (cursorPosition !== null) {
    event.preventDefault();
  }

  return cursorPosition;
}

export function setCursorPosition(ele: HtmlTextInputElement, pos: number) {
  // Setting selection{Start,End} in WebKit causes the input to be focused,
  // see https://bugs.webkit.org/show_bug.cgi?id=44219
  // Same with setSelectionRange(): https://bugs.webkit.org/show_bug.cgi?id=224425
  if (ele === document.activeElement) {
    ele.selectionStart = ele.selectionEnd = pos;
  }
}
