import { createNextState } from '@reduxjs/toolkit';
import { getType } from 'typesafe-actions';
import { type CompanySearchAction, actions } from './';

export interface CompanySearchItem {
  orgId: string;
  name: string;
  street: string;
  postalCode: string;
  incorporation: string;
}

export type CompanySearchResult =
  | {
      state: 'finished';
      term: string;
      items: CompanySearchItem[];
    }
  | {
      state: 'failed' | 'in_progress';
      term: string;
    };

export type CompanyValidationResult =
  | {
      state: 'valid';
      name: string;
    }
  | {
      state: 'invalid' | 'in_progress';
      name: string;
    };

export interface CompanySearchReducerState {
  search: { [term: string]: CompanySearchResult };
  valid: { [name: string]: CompanyValidationResult };
}

function makeInitialState(): CompanySearchReducerState {
  return { search: {}, valid: {} };
}

export const reducer = createNextState(
  (draft: CompanySearchReducerState, action: CompanySearchAction) => {
    switch (action.type) {
      case getType(actions.startSearch): {
        const term = action.payload;
        draft.search[term] = { state: 'in_progress', term };
        return;
      }

      case getType(actions.finishSearch): {
        draft.search[action.payload.term] = action.payload;
        return;
      }

      case getType(actions.startValidation): {
        draft.valid[action.payload] = {
          state: 'in_progress',
          name: action.payload,
        };
        return;
      }

      case getType(actions.finishValidation): {
        const { name, state } = action.payload;
        if (state === 'valid') {
          draft.valid[name] = { state: 'valid', name };
        } else {
          draft.valid[name] = { state: 'invalid', name };
        }
        return;
      }

      default:
        return draft;
    }
  },
  makeInitialState(),
);
