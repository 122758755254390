import styled from '@emotion/styled';
import { fonts } from 'folio-common-components';

export const SectionHeading = styled.h3`
  margin: 0;
  /* Copied from BaseLabel */
  display: block;
  margin-bottom: 4px;
  letter-spacing: 0.25px;
  ${fonts.font300medium};
`;
