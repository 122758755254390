import type * as React from 'react';
export const FikenLockupLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={141}
    height={56}
    fill="none"
    viewBox="0 0 141 56"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="#5239BA"
      fillRule="evenodd"
      d="M77.0168 42.351V27.1663h-7.1156V42.351h-3.7411V27.1663h-2.7506v-3.0076h2.7506v-3.1912c0-3.851 2.3474-6.125 6.0516-6.125.6971 0 1.3938.1098 2.0545.3298L73.606 18.4c-.4402-.1467-.8439-.1831-1.2471-.1831-1.504 0-2.4577.99-2.4577 2.8239v3.1179h10.8567V42.351h-3.7411Zm-.2565-21.0901v-4.2177h4.2542v4.2177h-4.2542Zm12.8368-4.5845v13.6073l6.1989-6.125h4.585l-6.4189 6.3086 7.5189 11.8837h-4.3646l-5.8316-9.2428-1.6872 1.6871v7.5557h-3.7412V16.6764h3.7412-.0005ZM119.82 34.0618h-14.415c.147 3.2277 2.311 5.5381 5.539 5.5381 2.97 0 4.364-1.6138 5.428-3.7776l2.897 1.7237c-1.687 3.4846-4.328 5.3185-8.436 5.3185-4.988 0-9.169-3.9248-9.169-9.6096 0-5.6854 4.181-9.6097 9.353-9.6097 5.282 0 8.803 3.7776 8.803 9.0962v1.3204Zm-8.877-7.409c-2.714 0-4.841 1.9073-5.538 4.5481h10.856c-.293-2.9712-2.787-4.5481-5.318-4.5481Zm29.233 3.3375V42.351h-3.741V31.0906c0-2.4941-1.467-3.9977-4.035-3.9977-2.604 0-4.768 2.2007-4.768 5.6485v9.6096h-3.741V24.1587h3.741v3.044c1.284-2.1273 3.558-3.5575 6.199-3.5575 3.557 0 6.345 2.2371 6.345 6.3451Z"
      clipRule="evenodd"
    />
    <path
      fill="#75ABF7"
      fillRule="evenodd"
      d="M20.7564 25.3314c-1.2739-.0014-2.4946-.5-3.3935-1.3856-.899-.8855-1.4029-2.0856-1.3996-3.336V18.784C15.9504 8.5305 24.3979.2029 34.8432.1712 38.6088.166 42.2886 1.274 45.4026 3.352c2.1528 1.4714 2.7056 4.3659 1.2414 6.5023-1.4643 2.1365-4.4029 2.7257-6.6031 1.3233-1.5328-1.0231-3.3441-1.5678-5.1977-1.563-5.1436.0182-9.3014 4.1204-9.2937 9.1695v1.8339c.0019 1.25-.5015 2.4496-1.4 3.3356-.899.8851-2.1197 1.3837-3.3931 1.3861"
      clipRule="evenodd"
    />
    <path
      fill="#5239BA"
      fillRule="evenodd"
      d="M36.6982 29.763H25.5447V18.7841c.0595-1.7184-.84-3.3318-2.3469-4.2082-1.5069-.8769-3.3806-.8769-4.8875 0-1.5065.8764-2.4064 2.4898-2.3465 4.2082v10.9871H4.8098C2.1538 29.7712 0 31.8846 0 34.4923c0 2.6078 2.1537 4.7222 4.8099 4.7222h11.1539v10.9866c-.0422 1.7078.8616 3.3034 2.3618 4.1693 1.4997.8659 3.36.8659 4.8597 0 1.4998-.8659 2.404-2.4615 2.3623-4.1693V39.2058h11.153c2.6567 0 4.8104-2.1139 4.8104-4.7216 0-2.6073-2.1537-4.7212-4.8104-4.7212"
      clipRule="evenodd"
    />
  </svg>
);
