import { createNextState } from '@reduxjs/toolkit';
import { getType } from 'typesafe-actions';
import { type CheckCompanyAction, actions } from '.';

export type CheckCompanyResult =
  | {
      state: {
        isOk: boolean;
        validSymbolsOk: boolean;
        lengthOk: boolean;
        existingNameOk: boolean;
        properNameOk: boolean;
        companyTypeOk: boolean;
      };
      name: string;
    }
  | {
      state: 'in_progress';
      name: string;
    };

export interface CheckCompanyReducerState {
  [name: string]: CheckCompanyResult;
}

function makeInitialState(): CheckCompanyReducerState {
  return {};
}

export const reducer = createNextState(
  (draft: CheckCompanyReducerState, action: CheckCompanyAction) => {
    switch (action.type) {
      case getType(actions.startValidation): {
        draft[action.payload] = {
          state: 'in_progress',
          name: action.payload,
        };
        return;
      }

      case getType(actions.finishValidation): {
        const { name, state } = action.payload;
        if (state?.isOk) {
          draft[name] = { state, name };
        } else {
          draft[name] = { state: state ?? 'in_progress', name };
        }
        return;
      }

      default:
        return draft;
    }
  },
  makeInitialState(),
);
