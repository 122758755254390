import type { RootState } from '..';
import type { CompanyNameCheck } from '../../gqltypes';

export function checkCompany(
  state: RootState,
  name: string,
): CompanyNameCheck | 'in_progress' {
  const item = state.companyCheck[name];

  if (item) {
    return item.state;
  } else {
    return 'in_progress';
  }
}
