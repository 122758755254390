/**
 * state and reducer stuff for app stuff, that is, loading indicators mostly
 * currently
 */

import { createNextState } from '@reduxjs/toolkit';
import { getType } from 'typesafe-actions';
import { type AppAction, actions } from './actions';

export type MessageSeverity = 'warning' | 'info' | 'error';

export interface Message {
  severity: MessageSeverity;
  body: string;
  id: string;
  timestamp: number;
}

export interface AppReducerState {
  booted: boolean;
  loading: boolean;
  saving: boolean;
  pristine: boolean;
  messages: Message[];
  lastStatusPollTime: number;
  bootIsSlow: boolean;
  bootFailed: boolean;
  bootedTime: number;
  brregProcessingTime?: number;
}

function makeInitialState(): AppReducerState {
  return {
    booted: false,
    loading: false,
    saving: false,
    pristine: true,
    messages: [],
    lastStatusPollTime: 0,
    bootIsSlow: false,
    bootFailed: false,
    bootedTime: 0,
  };
}

const reducer = createNextState((draft: AppReducerState, action: AppAction) => {
  switch (action.type) {
    case getType(actions.reset): {
      Object.assign(draft, makeInitialState());
      return;
    }

    case getType(actions.setBootIsSlow): {
      draft.bootIsSlow = action.payload;
      return;
    }

    case getType(actions.setLoadingActive): {
      draft.loading = action.payload;
      return;
    }

    case getType(actions.touchPollTime): {
      draft.lastStatusPollTime = Date.now();
      return;
    }

    case getType(actions.setSavingActive): {
      draft.saving = action.payload;
      return;
    }

    case getType(actions.setBooted): {
      draft.booted = action.payload;
      draft.bootedTime = Date.now();
      return;
    }

    case getType(actions.setBootFailed): {
      draft.bootFailed = action.payload;
      return;
    }

    case getType(actions.setPristine): {
      draft.pristine = action.payload;
      return;
    }

    case getType(actions.addMessage): {
      draft.messages = draft.messages.filter(e => e.id !== action.payload.id);
      draft.messages.push(action.payload);
      return;
    }

    case getType(actions.deleteMessage): {
      draft.messages = draft.messages.filter(e => e.id !== action.payload);
      return;
    }

    case getType(actions.setBrregProcessingTime): {
      draft.brregProcessingTime = action.payload || undefined;
      return;
    }

    default:
      return draft;
  }
}, makeInitialState());

export { reducer };
