import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { font100book, font300book } from './fonts';

export type TextfieldProps = {
  value: string;
  /** Triggers when the contents of the input is changed */
  onChange: (value: string) => void;
  /** Optional label shown above */
  label?: React.ReactNode;
  /**
   * Controls if and how a message is shown beneath the input:
   * If set to `{ kind: 'message', message: 'some text'}` then `some text` is
   * shown beneath the input.
   * If set to `{ kind: 'error', message: 'some error'}` then `some error` is
   * shown in red beneath the input.
   *  If set to `{ kind: 'placeholder' }` then empty space is added below the
   * input so the layout will not change if a message or error is added.
   *  If set to `{ kind: 'error' }` then the developer is responsible for
   * showing an error somewhere on the page themselves, and associate it with
   * the input by using `aria-labelledby`. The error flag is used to render the
   * input in red.
   */
  message?: MessageKinds;
};

type MessageContent = string | React.ReactElement;
export type MessageKinds =
  | { kind: 'message'; content: MessageContent }
  | { kind: 'error'; content?: MessageContent }
  | { kind: 'placeholder' };

export type Size = 'large' | 'medium';

export const textLikeInput = css`
  font: inherit;
  -webkit-appearance: none;
  border: 1px solid ${colors.wcagNonText};
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 8px;
  max-width: 100%;
  padding: 12px;
  margin: 0;

  &:focus {
    outline: none;
    border-color: ${colors.blue};
    box-shadow: inset 0 0 0 1px ${colors.blue}; /* FIXME: use focusShadow, but only 1px wide, or inset */
  }

  &:disabled {
    color: ${colors.wcagGray};
    background: ${colors.grayMediumOpaque};
    border-color: transparent;
    -webkit-text-fill-color: ${colors.wcagGray};
    opacity: 1;
  }

  &::placeholder {
    color: ${colors.grayShadowOpaque};
    opacity: 1;
  }

  -webkit-tap-highlight-color: transparent;
  @media (pointer: coarse) {
    transition: background 0.2s;

    :active {
      background: ${colors.grayLightOpaque};
    }
  }

  @media (prefers-contrast: more) {
    border-color: ${colors.black};
  }
`;

export function dynamicStyles(
  size: Size,
  hasIcon: boolean,
  rightContentWidth = 0,
) {
  switch (size) {
    case 'large':
      return css`
        ${font300book};
        padding: 19px 24px;
        padding-left: ${hasIcon ? '70px' : null}; /* 24*2+22 (icon size) */
        padding-right: ${rightContentWidth
          ? `${7 /* Width of space */ + rightContentWidth + 24}px`
          : null};
      `;

    case 'medium':
      return css`
        padding: 12px 16px;
        padding-left: ${hasIcon ? '54px' : null}; /* 16*2+22 (icon size) */
        padding-right: ${rightContentWidth
          ? `${5 /* Width of space */ + rightContentWidth + 16}px`
          : null};
      `;
  }
}

export const textLikeInputError = css`
  border-color: ${colors.wcagRed};

  &:focus {
    border-color: ${colors.wcagRed};
    box-shadow: inset 0 0 0 1px ${colors.wcagRed};
  }
`;

export function useTextfieldMessage(props: {
  message?: MessageKinds;
  id?: string;
  'aria-describedby'?: string;
}) {
  const id = React.useId();

  const { message } = props;
  const hasError = message?.kind === 'error';
  const hasMessage =
    message?.kind === 'message' ||
    (message?.kind === 'error' && message.content != null);
  const hasPlaceholder = message?.kind === 'placeholder';
  const messageId = `message_${id}`;
  const describedBy = props['aria-describedby'];

  const commonProps = {
    hasError,
    inputProps: {
      'aria-invalid': hasError ? true : undefined,
      'aria-describedby': hasMessage
        ? [messageId, describedBy].filter(Boolean).join(' ')
        : describedBy,
    },
  };

  if (hasMessage) {
    return {
      ...commonProps,
      messageEle: (
        <Message
          id={messageId}
          css={css`
            color: ${hasError ? colors.wcagRed : undefined};
          `}
        >
          {hasPlaceholder ? null : message.content}
        </Message>
      ),
    };
  }

  if (hasPlaceholder) {
    return {
      ...commonProps,
      messageEle: (
        <Message
          css={css`
            ::before {
              content: '';
              display: inline-block;
            }
          `}
        />
      ),
    };
  }

  return {
    ...commonProps,
    messageEle: undefined,
  };
}

const Message = styled.div`
  margin: 4px 0;
  ${font100book};
`;
