import { invariant } from 'folio-common-utils';
import type { RootState } from '../';
import type { Company, EditorReducerState, Person, Role } from './';

/**
 * Returns a list of all people with share count.
 */
export type PeopleSelectorResult = (Person & { shares: number })[];

export function getCeo(state: RootState): Person | undefined {
  const { ceo, people } = state.editor;
  if (ceo) {
    return people[ceo];
  } else {
    return undefined;
  }
}

export function getChair(state: RootState): Person {
  const { roles, people } = state.editor;
  const chair = roles.find(assignment => assignment.role === 'chair');

  invariant(chair, 'There must be a chair');

  return people[chair.id];
}

export function getBoardMember(state: RootState): Person[] {
  const { roles, people } = state.editor;
  return roles
    .filter(assignment => assignment.role === 'boardMember')
    .map(assignment => people[assignment.id]);
}

export function getDeputy(state: RootState): Person[] {
  const { roles, people } = state.editor;
  return roles
    .filter(assignment => assignment.role === 'deputy')
    .map(assignment => people[assignment.id]);
}

export function getAllBoardMembers(state: RootState): Person[] {
  return [getChair(state), ...getBoardMember(state), ...getDeputy(state)];
}

type PersonWithRolesAndOwnership = Person & {
  equity: number;
  isCeo: boolean;
  roles: Role[];
};

export function peopleWithRolesAndOwnership(
  state: RootState,
): PersonWithRolesAndOwnership[] {
  const { editor } = state;

  const people = Object.values(editor.people).map(person => {
    const equity =
      owners(editor).find(owner => owner.item.id === person.id)?.equity ?? 0;
    const isCeo = person.id === editor.ceo;
    const roles = editor.roles
      .filter(role => person.id === role.id)
      .map(role => role.role);

    const ret: PersonWithRolesAndOwnership = Object.assign({}, person, {
      equity,
      isCeo,
      roles,
    });
    return ret;
  });

  return people.filter(person => person.equity > 0 || person.roles.length > 0);
}

interface BaseOwner {
  equity: number;
  equityAsString: string;
  capital: number;
  fraction: number;
}

export interface CompanyOwner extends BaseOwner {
  kind: 'company';
  item: Company;
  signatories: Person[];
}

export interface PersonOwner extends BaseOwner {
  kind: 'person';
  item: Person;
}

export type Owner = CompanyOwner | PersonOwner;

export function getPeopleOwners(editor: EditorReducerState): Person[] {
  return owners(editor)
    .filter(isPersonOwner)
    .map(person => person.item);
}

export function getSignatories(editor: EditorReducerState): Person[] {
  return owners(editor)
    .filter(isCompanyOwner)
    .flatMap(company => company.signatories);
}

export function owners(editor: EditorReducerState): Owner[] {
  switch (editor.ownershipType) {
    case 'single': {
      const person = editor.people[editor.formOwner];
      const equity = Number(editor.coreInfo.capital) || 0;
      const capital = equity;
      const owner: PersonOwner = {
        kind: 'person',
        item: person,
        equity,
        equityAsString: equity.toString(),
        fraction: 1,
        capital,
      };
      return [owner];
    }
    case 'company': {
      const company = editor.singleCompanyOwner;

      if (!company) {
        return [];
      }

      const equity = Number(editor.coreInfo.capital) || 0;
      const capital = equity;
      const owner: CompanyOwner = {
        kind: 'company',
        item: company,
        equity,
        equityAsString: equity.toString(),
        fraction: 1,
        capital,
        signatories: company.signatories.map(e => editor.people[e]),
      };
      return [owner];
    }
    case 'multiple': {
      const shareCount = editor.coreInfo.shareCount;
      const totalShares = shareCount === '' ? 0 : Number(shareCount);
      const capital = Number(editor.coreInfo.capital) || 0;
      const equityMap = editor.equity;
      const idsWithEquity = Object.entries(equityMap)
        .filter(([_key, value]) => typeof value === 'string')
        .map(([key]) => key);

      return idsWithEquity.map(id => {
        const equity = Number(editor.equity[id]) || 0;
        const fraction = totalShares === 0 ? 0 : equity / totalShares;

        // if id is person
        if (editor.people[id] !== undefined) {
          const personOwner: PersonOwner = {
            kind: 'person',
            item: editor.people[id],
            equity,
            equityAsString: editor.equity[id],
            fraction,
            capital,
          };
          return personOwner;
          // else id is company
        } else {
          const companyOwner: CompanyOwner = {
            kind: 'company',
            item: editor.companies[id],
            equity,
            equityAsString: editor.equity[id],
            fraction,
            capital,
            signatories: editor.companies[id].signatories.map(
              e => editor.people[e],
            ),
          };
          return companyOwner;
        }
      });
    }
  }
}

export function getPersonByPnum(state: RootState, pNum: string) {
  if (pNum === '') {
    return undefined;
  }
  return Object.values(state.editor.people).find(e => e.pNum === pNum);
}

export function getPersonByIdOrPnum(
  state: RootState,
  p: { id: string; pNum?: string },
): Person | undefined {
  const item =
    p.pNum === undefined ? undefined : getPersonByPnum(state, p.pNum);
  if (item) {
    return item;
  } else {
    return state.editor.people[p.id];
  }
}

function isCompanyOwner(owner: Owner): owner is CompanyOwner {
  return owner.kind === 'company';
}

function isPersonOwner(owner: Owner): owner is PersonOwner {
  return owner.kind === 'person';
}

export function getCompanyOwner(state: RootState, orgId: string | undefined) {
  if (orgId === undefined) {
    return undefined;
  } else {
    return owners(state.editor)
      .filter(isCompanyOwner)
      .find(e => e.item.orgId === orgId);
  }
}
export function userCanOnboard(state: RootState, personId: string) {
  return personId === getChair(state).id || personId === getCeo(state)?.id;
}
