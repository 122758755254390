import { css } from '@emotion/react';
import { ProgressSpinner } from 'folio-common-components';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pageMap } from '../../paths';
import { type RootState, useActions, useSelector } from '../../state';

function selector(state: RootState) {
  return {
    foundingId: state.editor.id,
    bankSelection: state.founding.bankSelection,
    pristine: state.app.pristine,
    dirty: state.editor.dirty,
    saving: state.app.saving,
  };
}

const ResetBankSelection: React.FC = () => {
  const { foundingThunks, editorActions } = useActions();
  const { bankSelection, pristine, dirty, saving } = useSelector(selector);
  const { search: locationSearch } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (pristine) {
      // There wasn't a valid ID sent in the query. Go to frontpage
      navigate({ pathname: '/', search: locationSearch });
    } else if (saving || dirty) {
      // We're currently saving, so spinner should show
      return;
    } else if (bankSelection === 'OTHER_NAGGED' || bankSelection === 'OTHER') {
      // The founding is good, and we need to set it to `UNSET`.
      foundingThunks.setBankSelectionThunk('UNSET');
      editorActions.setDirty(true);
    } else if (bankSelection === 'FOLIO') {
      // Most likely, if someone ends up here, they have chosen Folio, but
      // have been "rejected" in the AML form, and they have to continue
      // with another bank.
      foundingThunks.setBankSelectionThunk('OTHER_NAGGED');
      editorActions.setDirty(true);
    } else {
      // It's not saving, and it's in a state where we should show status
      navigate({ pathname: pageMap.status, search: locationSearch });
    }
  }, [
    bankSelection,
    dirty,
    editorActions,
    foundingThunks,
    navigate,
    locationSearch,
    pristine,
    saving,
  ]);

  return (
    <div
      css={css`
        text-align: center;
      `}
    >
      <div
        css={css`
          display: inline-block;
        `}
      >
        <ProgressSpinner size={48} />
      </div>
    </div>
  );
};

// React.lazy only supports default exports:
// https://reactjs.org/docs/code-splitting.html#named-exports
// eslint-disable-next-line import/no-default-export
export default ResetBankSelection;
