import { type ActionType, createAction } from 'typesafe-actions';
import type { Page } from '../../paths';
import type {
  Company,
  CoreInfo,
  EditorReducerState,
  OwnershipType,
  Person,
  Role,
} from './';

export type PersonInfo = { id: string } & Partial<Person>;
type CompanyInfo = { id: string } & Partial<Company>;

export const actions = {
  /** Add or update a person. */
  setPersonInfo: createAction('SET_PERSON_INFO')<PersonInfo>(),
  setCompanyInfo: createAction('SET_COMPANY_INFO')<CompanyInfo>(),
  setSingleCompanyInfo: createAction('SET_SINGLE_COMPANY_INFO')<CompanyInfo>(),
  setCoreInfo: createAction('SET_CORE_INFO')<Partial<CoreInfo>>(),
  assignRole: createAction('ASSIGN_ROLE')<{ id: string; role: Role }>(),
  removeRole: createAction('REMOVE_ROLE')<{ id: string; role: Role }>(),
  removeFromAllRoles: createAction('REMOVE_FROM_ALL_ROLES')<string>(),
  setEquity: createAction('SET_EQUITY')<{ id: string; equity: string }>(),
  balanceEquity: createAction('BALANCE_EQUITY')(),
  removeAsOwner: createAction('REMOVE_AS_OWNER')<string>(),
  restoreSession: createAction('RESTORE_EDITOR_STATE')<EditorReducerState>(),
  createNew: createAction('CREATE_NEW')<string | undefined>(),
  setOwnershipType: createAction('SET_OWNERSHIP_TYPE')<OwnershipType>(),
  setFormOwner: createAction('SET_FORM_OWNER')<string>(),
  setFormOwnerAsAddress: createAction('SET_FORM_OWNER_AS_ADDRESS')<boolean>(),
  setEquityDirty: createAction('SET_EQUITY_DIRTY')<boolean>(),
  setDirty: createAction('SET_DIRTY')<boolean>(),
  setEditableBoard: createAction('SET_EDITABLE_BOARD')<boolean>(),
  assignCeo: createAction('ASSIGN_CEO')<string | undefined>(),
  clearRoles: createAction('CLEAR_ROLES')(),
  setEmailSubmitted: createAction('SET_EMAIL_SUBMITTED')<boolean>(),
  setFormOwnerInfoTracked: createAction('SET_FORM_OWNER_INFO_TRACKED')(),
  resetCompanyPurpose: createAction('CLEAR_PURPOSE')(),
  setCompanyPurpose: createAction('UPDATE_PURPOSE_DRAFT')<{
    purpose: string;
    verbosePurpose: string;
    activityId?: string;
    categoryId?: string;
  }>(),
  setOrgTransfer: createAction('SET_ORG_TRANSFER')<{
    signatory: Person;
    orgName: string;
    orgId: string;
  }>(),
  clearOrgTransfer: createAction('CLEAR_ORG_TRANSFER')(),

  setReadOnly: createAction('SET_READ_ONLY')(),
  markPageForValidation: createAction('MARK_PAGE_FOR_VALIDATION')<Page>(),
};

export type EditorAction = ActionType<typeof actions>;
