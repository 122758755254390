import styled from '@emotion/styled';
import * as React from 'react';
import { baseButtonStyle } from './sharedStyles';

const Button = styled.button`
  ${baseButtonStyle};
  text-align: left;
  border-radius: 4px;
`;

const BasePlainButton: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ type = 'button', ...rest }, ref) => (
  <Button type={type} ref={ref} {...rest} />
);

export const PlainButton = React.forwardRef(BasePlainButton);
