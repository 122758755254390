import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { pageMap } from '../../paths';

const StepPage1: React.FC = () => {
  return <Navigate to={pageMap.step1} />;
};

// React.lazy only supports default exports:
// https://reactjs.org/docs/code-splitting.html#named-exports
// eslint-disable-next-line import/no-default-export
export default StepPage1;
