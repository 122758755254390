import styled from '@emotion/styled';
import * as React from 'react';
import { FikenLockupLogo } from '../../logos/fiken-lockup-logo';
import { pageMap } from '../../paths';
import { Page } from '../Page';
import { HeaderBar, StyledLink } from './shared';
import type { Props } from './index';

const Wrapper = styled.div`
  padding: 1rem 2rem;
  display: grid;
  grid-template-columns: auto 1fr;
`;

const Heading = styled.h1`
  margin: 0;
`;

// const StyledLogo = styled(Logo)`
//   display: block;
//   margin-right: 16px;
// `;

export const LargeHeaderBar: React.FC<Props> = ({ className }) => (
  <HeaderBar className={className}>
    <Page>
      <Wrapper>
        <Heading>
          <StyledLink
            to={{ pathname: pageMap.step1, search: window.location.search }}
          >
            <FikenLockupLogo height={40} width={141 / (56 / 40)} />
          </StyledLink>
        </Heading>
      </Wrapper>
    </Page>
  </HeaderBar>
);
