import { type ActionType, createAction } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import type { Message, MessageSeverity } from './';

export const actions = {
  reset: createAction('RESET_APP')(),
  setBootIsSlow: createAction('SET_BOOT_IS_SLOW')<boolean>(),
  setBrregProcessingTime: createAction('SET_BRREG_PROCESSING_TIME')<
    number | null
  >(),
  setLoadingActive: createAction('SET_LOADING_STATE', () => true)(),
  setLoadingIdle: createAction('SET_LOADING_STATE', () => false)(),
  setSavingActive: createAction('SET_SAVING_STATE', () => true)(),
  setSavingIdle: createAction('SET_SAVING_STATE', () => false)(),
  setBooted: createAction('SET_BOOTED', () => true)(),
  setBootFailed: createAction('SET_BOOT_FAILED', () => true)(),
  setPristine: createAction('SET_PRISTINE')<boolean>(),
  deleteMessage: createAction('DELETE_MESSAGE')<string>(),
  touchPollTime: createAction('TOUCH_POLL_TIME')(),
  addMessage: createAction(
    'ADD_MESSAGE',
    (arg: { body: string; id?: string; severity?: MessageSeverity }) => {
      const { body, severity, id } = arg;
      return {
        timestamp: Date.now(),
        severity: severity || 'warning',
        id: id || uuidv4(),
        body,
      } as Message;
    },
  )(),
};

export type AppAction = ActionType<typeof actions>;
