import * as React from 'react';
import { Button } from '../buttons';

export const ErrorRoute: React.FC = () => (
  <>
    <p>En feil har intruffet</p>
    <p>
      <Button onClick={() => window.location.reload()}>Prøv på nytt</Button>
    </p>
  </>
);
