/* This file is autogenerated */

import { css } from '@emotion/react';

export const font100book = css`
  font-size: 0.875rem;
  line-height: 1.428571429;
  font-weight: 400;
`;

export const font100medium = css`
  font-size: 0.875rem;
  line-height: 1.428571429;
  font-weight: 500;
`;

export const font200book = css`
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 400;
`;

export const font200demi = css`
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 600;
`;

export const font200medium = css`
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 500;
`;

export const font300book = css`
  font-size: 1.125rem;
  line-height: 1.333333333;
  font-weight: 400;
`;

export const font300demi = css`
  font-size: 1.125rem;
  line-height: 1.333333333;
  font-weight: 600;
`;

export const font300medium = css`
  font-size: 1.125rem;
  line-height: 1.333333333;
  font-weight: 500;
`;

export const font400bold = css`
  font-size: 1.25rem;
  line-height: 1.3;
  font-weight: 700;

  @media (min-width: 500px) {
    font-size: 1.375rem;
    line-height: 1.363636364;
  }
`;

export const font400book = css`
  font-size: 1.25rem;
  line-height: 1.3;
  font-weight: 400;

  @media (min-width: 500px) {
    font-size: 1.375rem;
    line-height: 1.363636364;
  }
`;

export const font400medium = css`
  font-size: 1.25rem;
  line-height: 1.3;
  font-weight: 500;

  @media (min-width: 500px) {
    font-size: 1.375rem;
    line-height: 1.363636364;
  }
`;

export const font500bold = css`
  font-size: 1.375rem;
  line-height: 1.272727273;
  font-weight: 700;

  @media (min-width: 500px) {
    font-size: 1.625rem;
    line-height: 1.307692308;
  }
`;

export const font500book = css`
  font-size: 1.375rem;
  line-height: 1.272727273;
  font-weight: 400;

  @media (min-width: 500px) {
    font-size: 1.625rem;
    line-height: 1.307692308;
  }
`;

export const font500medium = css`
  font-size: 1.375rem;
  line-height: 1.272727273;
  font-weight: 500;

  @media (min-width: 500px) {
    font-size: 1.625rem;
    line-height: 1.307692308;
  }
`;

export const font600black = css`
  font-size: 1.75rem;
  line-height: 1.214285714;
  font-weight: 900;

  @media (min-width: 500px) {
    font-size: 2.25rem;
    line-height: 1.277777778;
  }
`;

export const font600book = css`
  font-size: 1.75rem;
  line-height: 1.214285714;
  font-weight: 400;

  @media (min-width: 500px) {
    font-size: 2.25rem;
    line-height: 1.277777778;
  }
`;

export const font600medium = css`
  font-size: 1.75rem;
  line-height: 1.214285714;
  font-weight: 500;

  @media (min-width: 500px) {
    font-size: 2.25rem;
    line-height: 1.277777778;
  }
`;

export const font700black = css`
  font-size: 2rem;
  line-height: 1.125;
  font-weight: 900;

  @media (min-width: 500px) {
    font-size: 2.875rem;
    line-height: 1.260869565;
  }
`;

export const font800black = css`
  font-size: 2.25rem;
  line-height: 1.111111111;
  font-weight: 900;

  @media (min-width: 500px) {
    font-size: 4rem;
    line-height: 1.125;
  }

  @media (min-width: 1150px) {
    font-size: 6.25rem;
    line-height: 1.1;
  }
`;
