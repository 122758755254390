import { notEmpty } from 'folio-common-utils';
import type * as React from 'react';

type KwArgs<T extends { [key: string]: any } = any> = { [K in keyof T]: T[K] };
type MsgFun<T extends { [K in keyof T]: T[K] } = any> = (
  args: KwArgs<T>,
) => React.ReactNode;
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type MsgFunArgs<T> = T extends MsgFun<infer K> ? K : {};
type MsgLiteral = React.ReactNode;
type MsgItem = MsgLiteral | MsgFun;
type StringKind = { [id: string]: MsgLiteral };
type KindComponent<T extends StringKind> = React.FC<{ kind: keyof T }>;
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
type MsgComponent<T> = T extends Function
  ? React.FC<MsgFunArgs<T>>
  : T extends StringKind
  ? KindComponent<T>
  : React.FC;
type MsgMap<T extends { [id: string]: MsgItem | StringKind }> = {
  [K in keyof T]: MsgComponent<T[K]>;
};

function validateKindMap(msg: StringKind) {
  const values = Object.values(msg);
  if (values.length === 0) {
    throw new Error('empty kind map');
  }

  if (values.some(e => e == null)) {
    throw new Error('some kind was null');
  }

  const types = values.map(e => typeof e);
  const first = types[0];
  if (!types.every(e => e === first)) {
    throw new Error(`Not all items have same type for ${msg}`);
  }

  if (first === 'function') {
    const args = values
      .filter(notEmpty)
      .map(e => e.toString())
      .map(e => e.replace(/\s/g, ''))
      .map(e => e.match(/\({(.*?)}\)/))
      .map(e => (e ? e[1] : ''))
      .map(e => e.split(','))
      .map(e => e.sort())
      .map(e => e.join())
      .sort();

    const first = args[0];
    if (!args.every(e => e === first)) {
      throw new Error('All function messages must have same props');
    }
  }
}

export function msgsToComponents<
  T extends { [id: string]: MsgItem | StringKind },
>(messages: T): MsgMap<T> {
  const ret = {};
  Object.keys(messages).forEach(key => {
    const item = messages[key];
    if (item == null) {
      ret[key] = () => item;
    } else if (typeof item === 'function') {
      ret[key] = props => (item as any)(props);
    } else if (Symbol.for('react.element') === (item as any).$$typeof) {
      ret[key] = () => item;
    } else if (typeof item === 'object') {
      validateKindMap(item as any);
      ret[key] = props => {
        const { kind, ...rest } = props;
        const kindItem = item[kind];
        if (typeof kindItem === 'function') {
          return kindItem(rest);
        } else {
          return kindItem;
        }
      };
    } else {
      ret[key] = () => item;
    }
  });
  return ret as any;
}
