import { type ActionType, createAction } from 'typesafe-actions';
import type { CompanyNameCheck } from '../../gqltypes';

export const actions = {
  startValidation: createAction('START_VALIDATION')<string>(),
  finishValidation: createAction('FINISH_VALIDATION')<{
    name: string;
    state: CompanyNameCheck | null;
  }>(),
};

export type CheckCompanyAction = ActionType<typeof actions>;
