import * as React from 'react';

export const ArrowRightIcon: React.FC = props => {
  return (
    <svg width={24} height={24} fill="none" aria-hidden={true} {...props}>
      <path
        d="M5 12h14M12 5l7 7-7 7"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowDownIcon: React.FC = props => {
  return (
    <svg width={24} height={24} fill="none" aria-hidden={true} {...props}>
      <path
        d="M12 5v14M19 12l-7 7-7-7"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckmarkIcon: React.FC = props => {
  return (
    <svg width={24} height={24} fill="none" aria-hidden={true} {...props}>
      <path
        d="M20 7l-11 11l-5-5"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
