import { css } from '@emotion/react';

export const baseButtonStyle = css`
  /* Maybe use 'all: unset' here */
  font: inherit;
  text-align: center;
  cursor: pointer;
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  color: inherit;
  transition: background-color 0.2s;

  &::-moz-focus-inner {
    border: 0;
  }
`;
