import * as Sentry from '@sentry/react';
import { waitResolve } from 'folio-common-utils';
import * as services from '../../services';
import { settings } from '../../settings';
import { type EditorReducerState, actions as editorActions } from '../editor';
import { actions as foundingActions } from '../founding';
import type { Dispatch, GetState } from '../types';
import { actions as appActions } from './';

export function boot(id?: string, readOnly?: boolean) {
  return async function (dispatch: Dispatch) {
    dispatch(appActions.setLoadingActive());
    dispatch(appActions.setBootIsSlow(false));

    if (id) {
      try {
        const timeoutSym = {};
        const loadPromise = services.loadAppState({ id });

        const race = await Promise.race([
          loadPromise,
          waitResolve(timeoutSym, 500),
        ]);

        if (race === timeoutSym) {
          dispatch(appActions.setBootIsSlow(true));
        }

        const res = await loadPromise;

        if (res.body) {
          const body = res.body as unknown as EditorReducerState;
          dispatch(editorActions.restoreSession(body));
          dispatch(appActions.setBrregProcessingTime(res.brregProcessingTime));
          dispatch(appActions.setPristine(false));
        }

        if (res.founding) {
          dispatch(foundingActions.updateFounding(res.founding));
        }

        if (readOnly) {
          dispatch(editorActions.setReadOnly());
        }
      } catch (error) {
        dispatch(appActions.setBootFailed());
        Sentry.addBreadcrumb({
          message: 'Failed loading app state',
          data: { id },
        });
        Sentry.captureException(error);
        // fixme: better error handling here
        // window.location.search = '';
      }
    } else {
      dispatch(appActions.reset());
      dispatch(foundingActions.reset());
      dispatch(editorActions.createNew(undefined));
    }

    dispatch(appActions.setLoadingIdle());
    dispatch(appActions.setBooted());
  };
}

export function updateMessageToast() {
  return function (dispatch: Dispatch, getState: GetState) {
    const state = getState();
    const messages = state.app.messages;

    const now = Date.now();
    messages.forEach(e => {
      const age = now - e.timestamp;
      if (age > settings.toastTimeout) {
        dispatch(appActions.deleteMessage(e.id));
      }
    });
  };
}
