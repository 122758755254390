import * as React from 'react';
import { createContainer } from 'unstated-next';
import { getIntercomConfig } from '.';

function useIntercomControls() {
  const [isBooted, setIsBooted] = React.useState(false);
  const chatIFrameRef = React.useRef<HTMLIFrameElement>();
  const { current: config } = React.useRef(getIntercomConfig());

  React.useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      let message;
      try {
        message = JSON.parse(event.data);
      } catch {
        // Probably couldn't parse JSON because the event is
        // from a different source; ignore it.
        return;
      }

      if (message.source !== 'folio-chat') {
        return;
      }

      switch (message.kind) {
        case 'ready': {
          setIsBooted(true);
          return;
        }

        case 'received-unsupported-message': {
          return;
        }
      }
    };

    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, []);

  const setIFrameRef = React.useCallback((iFrame: HTMLIFrameElement) => {
    chatIFrameRef.current = iFrame;
  }, []);

  const isReady = isBooted && chatIFrameRef.current != null;

  const show = React.useCallback(() => {
    if (!isReady) {
      return;
    }

    chatIFrameRef.current?.contentWindow?.postMessage(
      JSON.stringify({ source: 'folio', kind: 'show' }),
      config.intercomUrl,
    );
  }, [config, isReady]);

  const shutdown = React.useCallback(() => {
    if (!isReady) {
      return;
    }

    chatIFrameRef.current?.contentWindow?.postMessage(
      JSON.stringify({ source: 'folio', kind: 'shutdown' }),
      config.intercomUrl,
    );
  }, [config, isReady]);

  return React.useMemo(
    () => ({
      isReady,
      setIFrameRef,
      show,
      shutdown,
    }),
    [isReady, setIFrameRef, show, shutdown],
  );
}

const { Provider, useContainer } = createContainer(useIntercomControls);
export {
  Provider as IntercomControlsProvider,
  useContainer as useIntercomControls,
};
