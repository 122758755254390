import { Global, css } from '@emotion/react';
import { fonts } from 'folio-common-components';
import * as React from 'react';
/* eslint-disable import/no-internal-modules, @typescript-eslint/ban-ts-comment */
// @ts-expect-error
import basisBold from './fonts/basis-grotesque-bold-pro.woff2';
// @ts-expect-error
import basisMedium from './fonts/basis-grotesque-medium-pro.woff2';
// @ts-expect-error
import basisRegular from './fonts/basis-grotesque-regular-pro.woff2';
/* eslint-enable */

export const GlobalStyles: React.FC<{
  styles?: ReturnType<typeof css>;
}> = ({ styles }) => (
  <Global
    styles={[
      css`
        @font-face {
          font-family: 'Basis Grotesque';
          src: url('${basisRegular}') format('woff2');
          font-display: swap;
        }

        @font-face {
          font-family: 'Basis Grotesque';
          src: url('${basisMedium}') format('woff2');
          font-weight: 500;
          font-display: swap;
        }

        @font-face {
          font-family: 'Basis Grotesque';
          src: url('${basisBold}') format('woff2');
          font-weight: 700;
          font-display: swap;
        }

        @font-face {
          font-family: fallback;
          src: local(Arial);
          ascent-override: 99.2%;
          descent-override: 33.6%;
        }

        *,
        ::before,
        ::after {
          box-sizing: border-box;
        }

        html,
        body {
          height: 100%;
        }

        html {
          font-family: 'Basis Grotesque', fallback, sans-serif,
            'Apple Color Emoji', 'Segoe UI Emoji';
          ${fonts.font200book};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: 'kern', 'liga';

          --black-500: #272727;
          --blue-100: #f8fafe;
          --blue-200: #ecf4ff;
          --grey-200: #f5f5f5;
          --grey-500: #e0e0e0;
          --grey-600: #d8d8d8;
          --grey-700: #939393;
          --grey-900: #676767;
          --green-300: #dbf7ef;
          --green-500: #6bd1b7;
          --green-700: #00997d;
          --purple-400: #ddd8fe;
          --purple-800: #5239ba;
          --purple-900: #39219c;
          --system-blue-700: #0539f7;
          --white-500: #fff;

          /* FIXME */
          --fiken-green-hover: #02664e;
          --fiken-purple-light: #e6e3fe;
          --fiken-purple-extra-light: #f2f0fe;

          --autosuggest-border-radius: 4px;
          --autosuggest-border-width: 1px;
          --autosuggest-highlight-color: var(--fiken-purple-extra-light);
          --autosuggest-border-color: var(--purple-800);
        }

        body {
          margin: 0;
          background: var(--grey-200);
          color: var(--black-500);
          /* Avoid page jumping when navigating, depending on the height of the content */
          overflow-y: scroll;
          -webkit-text-size-adjust: 100%;
          touch-action: manipulation;
        }

        a {
          color: var(--system-blue-700);
          border-radius: 4px;
        }

        a:hover {
          text-decoration: none;
        }

        b,
        strong {
          font-weight: 500;
        }

        p {
          margin: 1em 0;
        }

        label {
          -webkit-tap-highlight-color: transparent;
        }

        .js-focus-visible *:focus:not(.focus-visible) {
          outline: none;
        }

        .js-focus-visible .focus-visible {
          outline: none;
          box-shadow: 0 0 0 2px var(--system-blue-700);
        }

        [hidden] {
          display: none !important;
        }

        /* Component overrides */

        input,
        textarea,
        button,
        select {
          border-radius: 4px !important;
        }

        :is([type='text'], [type='email'], [type='tel'], textarea, select):not(
            [aria-invalid='true']
          ) {
          border-color: var(--grey-500) !important;

          @media (prefers-contrast: more) {
            border-color: var(--black-500) !important;
          }
        }

        :is([type='text'], [type='email'], [type='tel'], textarea, select):not(
            [aria-invalid='true']
          ):focus {
          border-color: var(--purple-800) !important;
          box-shadow: none !important;
        }

        :checked + [data-graphics] [data-graphics-background] {
          fill: var(--green-700) !important;
          stroke: var(--green-700) !important;
        }

        .focus-visible + [data-graphics] [data-graphics-focus] {
          stroke: var(--purple-800) !important;
        }
      `,
      styles,
    ]}
  />
);
