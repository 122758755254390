/**
 * File contains constant settings that are not chanted at runtime
 * or loaded from environment.
 */

interface GlobalSettings {
  // in millis
  toastTimeout: number;

  // Percentage of company a person must own to automatically
  // be assigned to the board. from 0-100. If 0, everyone with shares go on
  // the board.
  ownershipBoardMemberThreshold: number;

  // Time to wait for network operations. In ms.
  networkTimeout: number;

  // Time to wait between updates to status page. In ms.
  statusPollTimeout: number;

  // Time to wait while booting before showing spinner. In ms.
  slowBootThreshold: number;
}

const options: GlobalSettings = {
  toastTimeout: 10000,
  ownershipBoardMemberThreshold: 0,
  networkTimeout: 8000,
  statusPollTimeout: 60000,
  slowBootThreshold: 500,
};

function validate(settings: GlobalSettings): GlobalSettings {
  if (
    settings.ownershipBoardMemberThreshold > 100 ||
    settings.ownershipBoardMemberThreshold < 0
  ) {
    throw new Error(
      `ownershipBoardMemberThreshold must be between 0 and 100. was ${settings.ownershipBoardMemberThreshold}`,
    );
  }
  return settings;
}

export const settings = validate(options);
