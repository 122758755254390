import { createNextState } from '@reduxjs/toolkit';
import { getType } from 'typesafe-actions';
import { type FoundingAction, type FoundingReducerState, actions } from './';

function makeInitialState(): FoundingReducerState {
  return {
    id: '',
    signerURLs: [],
    signingStatus: 'OPEN',
    documentURL: null,
    uploadReceiptURL: null,
    events: [],
    accountNumber: null,
    foundingDetails: null,
    bankSelection: null,
    accountingSelection: null,
    virksomhetsInfoSignerURLs: [],
    orgTransferSignerURLs: [],
  };
}

export const reducer = createNextState(
  (draft: FoundingReducerState, action: FoundingAction) => {
    switch (action.type) {
      case getType(actions.updateFounding): {
        return Object.assign(draft, action.payload);
      }

      case getType(actions.reset): {
        return Object.assign(draft, makeInitialState());
      }

      default:
        return draft;
    }
  },

  makeInitialState(),
);
