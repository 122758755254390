import { ClassNames, type SerializedStyles, css } from '@emotion/react';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
// `import ReactAutosuggest` doesn't work here for some reason
import {
  type AutosuggestPropsSingleSection,
  type InputProps as BaseInputProps,
  default as ReactAutosuggest,
} from 'react-autosuggest';
import { Spinner as BaseSpinner } from '../Spinner';
import { TextInputContainer } from '../TextInput';

export type { ChangeEvent } from 'react-autosuggest';

export interface InputProps<T> extends BaseInputProps<T> {
  css?: SerializedStyles;
}

export interface Props<T>
  extends Omit<AutosuggestPropsSingleSection<T>, 'inputProps'> {
  inputProps: InputProps<T>;
  icon?: React.ReactNode;
  loading?: boolean;
}

export function Autosuggest<T = unknown>({ icon, ...props }: Props<T>) {
  return (
    <ClassNames>
      {({ css }) => {
        const theme: Props<T>['theme'] = {
          suggestion: css`
            padding: 4px 8px;
            cursor: default;
            border-radius: var(--autosuggest-border-radius, 8px);
            background-clip: padding-box;
            border: 2px solid transparent;
          `,
          suggestionHighlighted: css`
            background-color: var(
              --autosuggest-highlight-color,
              ${colors.blueLight}
            );
          `,
          suggestionsList: css`
            padding: 0;
            margin: 0;
            list-style: none;
          `,
          container: css`
            position: relative;
          `,
          suggestionsContainerOpen: css`
            position: absolute;
            z-index: 1;
            width: 100%;
            background: #fff;
            border: var(--autosuggest-border-width, 2px) solid
              var(--autosuggest-border-color, ${colors.blue});
            border-radius: 0 0 var(--autosuggest-border-radius, 8px)
              var(--autosuggest-border-radius, 8px);
            margin-top: -2px;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            max-height: 80vh;
          `,
          inputOpen: css`
            border-color: var(--autosuggest-border-color, ${colors.blue});
            border-radius: var(--autosuggest-border-radius, 8px)
              var(--autosuggest-border-radius, 8px) 0 0;
          `,
        };

        return (
          <ReactAutosuggest
            {...props}
            theme={{ ...theme, ...props.theme }}
            renderInputComponent={({ ref, onChange, ...inputProps }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { size, ...rest } = inputProps;
              return (
                <TextInputContainer
                  icon={icon}
                  onChange={onChange}
                  {...rest}
                  ref={ele => {
                    if (typeof ref === 'function') {
                      ref(ele);
                    }

                    const inputRef = props.inputProps.ref;
                    if (typeof inputRef === 'function') {
                      inputRef(ele);
                    } else if (inputRef) {
                      // @ts-expect-error `current` is read-only, but should it?
                      inputRef.current = ele;
                    }
                  }}
                  data-testid="autosuggest"
                  rightContent={
                    props.loading
                      ? { content: <Spinner />, width: 0 }
                      : undefined
                  }
                />
              );
            }}
          />
        );
      }}
    </ClassNames>
  );
}

const Spinner: React.FC = () => {
  return (
    <BaseSpinner
      // Semi-transparent background for nicer text overlay
      css={css`
        background: rgb(255 255 255 / 0.5);
        border-radius: 50%;
        outline: 2px solid rgb(255 255 255 / 0.5);
      `}
    />
  );
};
